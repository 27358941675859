"use strict";

define('RaceProgramCtrl',[
    "MtpDisplayModel",
    "MyBetsCurrentRaceInputBuilder",
    "RaceStatusUtils",
    "lodash"
], function(MtpDisplay, MyBetsCurrentRaceInputBuilder, RaceStatusUtils, _) {
    function RaceProgramCtrl(
        $scope,
        RaceUrlFac,
        RaceInfoFac,
        RaceVideoFac,
        $q,
        $route,
        $location,
        $uibModal,
        $rootScope,
        GTMFac,
        METADATA,
        ProgramPageRunnersFac,
        TalentPicksEventsFactory,
        GraphRaceProgram,
        GraphRaceWillPays,
        GraphRaceFac,
        RaceMtpStatusFac,
        WagerProfileFac,
        VideoPopOutFac,
        GraphRaceBettingInterests,
        FindModalContainerFac,
        $interval,
        $timeout,
        $filter,
        $log,
        UserPromotionsSvc
    ) {
        var unbind = [];
        var routeParams = RaceUrlFac.getRacesInfoFromRouteParams();

        var graphSubVolatileData, graphSubWillPays, programPagePoller;
        var graphPollVolatileData =
            $filter("CMSLabels")("low", "graphqlPollingRates") || 30000; // in ms
        var graphPollWillPays =
            $filter("CMSLabels")("high", "graphqlPollingRates") || 120000; // in ms
        var graphTimeoutPollData;
        var initialFetchRetry = 3;
        var fetchRetry = initialFetchRetry;
        var nextRaceBis;
        var updateShowLiveVideo = true;
        $scope.trackOnChange = function(track) {
            $location.url(
                RaceUrlFac.generateRacePath(
                    track.trackAbbr,
                    track.trackName,
                    track.raceNumber
                )
            );
        };

        $scope.$on("video:popOut", function(evt, race) {
            _popOutVideoPlayer(race);
        });

        $scope.selectedTrack = "Selected Track";

        $scope.mtpDisplay = {};

        var confirmDialog = null;

        $scope.data = {
            loading: true,
            handicapLoading: false,
            showLiveVideo: true,
            updateShowLiveVideo: true,
            raceCancelled: false,
            hasRaceData: undefined,
            raceOpenForBetting: undefined, // tests were relying in this to be undefined...
            raceOpenForLiveVideo: undefined, // tests were relying in this to be undefined...
            hasPromoTag: false
        };

        $scope.events = {
            closeRacesListModal: _closeRacesListModal,
            changeTab: _changeTab,
            popOutVideoPlayer: _popOutVideoPlayer
        };
        $scope.raceInfoSelectedTab = "RIT_H";

        $scope.todaysRaceDate =
            RaceMtpStatusFac.getRaceDate() || moment().format("YYYY-MM-DD");

        var unbindOnSessionOnce = $scope.$on("session", function() {
            var path = $location.path().split("/autoplay/true");
            if (routeParams.raceNumber) {
                _pollRaceProgramData();
                $scope.data.hasRaceData = true;
            } else {
                $location.path(path[0]);
                $scope.data.hasRaceData = false;
            }
            unbindOnSessionOnce();
        });

        unbind.push(unbindOnSessionOnce);

        function _pollRaceProgramData() {
            if (programPagePoller) {
                $interval.cancel(programPagePoller);
            }
            _getGraphRaceProgramData();
            programPagePoller = $interval(_getGraphRaceProgramData, 120000);
        }

        function _changeTab(tabName) {
            $scope.raceInfoSelectedTab = tabName;
            var obj = {
                RIT_H: "Free Handicapping",
                RIT_P: "Probables",
                RIT_C: "Changes",
                RIT_PLS: "Pools",
                RIT_W: "Will Pays"
            };

            var p = {};
            p.screenName = "Races";
            p.sectionName = "Races | " + obj[tabName];

            $rootScope.appTitle = p.sectionName;
        }

        /**
         * Popout video player
         * @param  {Object}  race  Race/track info
         */
        function _popOutVideoPlayer(race) {
            // side effects...
            routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
            _sendGTMVideoClick();

            return VideoPopOutFac.popOutVideo(race);
        }

        function _sendGTMVideoClick() {
            var eventData = GTMFac.gaEventBuilder()
                .withGaEventAction(GTMFac.Tvg4ScreenName() + " Click")
                .withGaEventCategory("Site Click")
                .withGaEventLabel("Right Rail | Video Player | Pop Out")
                .withEventLabel("RightRail-PopOutVideo")
                .build();

            GTMFac.GTMEvent().send(
                $rootScope,
                "siteClick",
                GTMFac.genericEvent(eventData)
            );
        }

        function _getGraphRaceBettingInterests(trackAbbr, raceNumber) {
            return GraphRaceBettingInterests.get({
                trackAbbr: trackAbbr,
                raceNumber: raceNumber,
                wagerProfile: WagerProfileFac.getSessionOrGenericProfile(),
                withWagerTypes: false,
                withRunners: false,
                withOdds: false,
                forceFetch: false
            });
        }

        function _raceHasProbablesWithNextLeg(race) {
            var hasDailyDoubleWagerType = _.findIndex(race.wagerTypes, function(
                wt
            ) {
                return wt.type.code === "DB";
            });

            return hasDailyDoubleWagerType >= 0 && race.probables;
        }

        function _validateGreyhoundRoute(raceIsGreyhound) {
            return (
                ($rootScope.isGreyhoundRace && raceIsGreyhound) ||
                (!$rootScope.isGreyhoundRace && !raceIsGreyhound)
            );
        }

        function _getGraphRaceProgramData() {
            var queryParams = {
                trackAbbr: routeParams.trackAbbr,
                raceNumber: routeParams.raceNumber,
                wagerProfile: WagerProfileFac.getSessionOrGenericProfile(),
                forceFetch: true,
                queryOptions: {
                    product: window.__TVG_GLOBALS__.PRODUCT,
                    brand: window.__TVG_GLOBALS__.BRAND,
                    withDetails: true,
                    withHandicapping: true,
                    withBettingInterests: true,
                    withBiPools: true,
                    withLateChanges: true,
                    withProbables: true,
                    withPools: true,
                    withWagerTypes: true,
                    withWillPays: true
                }
            };
            GraphRaceProgram.get(queryParams)
                .then(function(data) {
                    var race = data.race;
                    var deferred = $q.defer();

                    if (!_validateGreyhoundRoute(race.isGreyhound)) {
                        deferred.reject("invalid_route");
                        return deferred.promise;
                    } else {
                        if (_raceHasProbablesWithNextLeg(race)) {
                            // so, this means we have a daily double bet type for this race and probables to show
                            // this also means the probables table will have an option to see probables for a daily double wager type
                            // and that means that table will represent the BIs for this exact race, and the next race
                            // so lets fetch that next race to have to know how much BI's there are and it's saddle colors
                            _getGraphRaceBettingInterests(
                                race.track.code,
                                parseInt(race.number, 10) + 1
                            ).then(function(response) {
                                nextRaceBis = response.race;
                                race.nextRace = nextRaceBis;
                                deferred.resolve(race);
                            });

                            return deferred.promise;
                        } else {
                            nextRaceBis = null;
                        }
                        return race;
                    }
                })
                .then(function(race) {
                    var raceData = GraphRaceFac.buildRace(race);
                    $rootScope.loadingTrack = false;
                    $rootScope.$emit("newTrackData", raceData.trackAbbr, [
                        raceData
                    ]);

                    _fetchPollingData(raceData, true);

                    $rootScope.$emit("raceChangedLoaded");

                    $scope.data.handicapLoading = false;
                    if (
                        raceData.handicapping &&
                        raceData.handicapping.handicapData.length
                    ) {
                        $scope.data.currentRaceHandicappingData =
                            raceData.handicapping;
                        ProgramPageRunnersFac.updateHandicapping(
                            raceData.handicapping
                        );
                        $scope.$broadcast("Hadicapping::Information");
                    }

                    if ($scope.$root && !$scope.$root.$$phase) {
                        $scope.$apply();
                    }

                    if (graphTimeoutPollData) {
                        $timeout.cancel(graphTimeoutPollData);
                    }

                    fetchRetry = initialFetchRetry;

                    // Poll for volatile data while race is not official
                    if (
                        !RaceStatusUtils.isStatusRaceOfficial(raceData.status)
                    ) {
                        graphTimeoutPollData = $timeout(function() {
                            _subscribeGraphData();
                        }, graphPollVolatileData);
                    } else {
                        _unsubscribeGraphRaceProgram();
                    }

                    $scope.data.currentRace.isUserOptedinPromo = false;
                    if (
                        _.get($rootScope, "activeFeatures.racePromoLink", false)
                    ) {
                        $scope.data.sendGTMPromo = function() {
                            var eventData = GTMFac.gaEventBuilder()
                                .withGaEventAction("Navigated To")
                                .withGaEventCategory("Navigation")
                                .withGaEventLabel("promos")
                                .withModule("racecard")
                                .withTag(
                                    $scope.data.currentRace.isUserOptedinPromo
                                        ? "optedin"
                                        : "notoptedin"
                                )
                                .build();

                            var event = GTMFac.genericEvent(eventData);
                            event.destinationUrl =
                                window.location.origin +
                                $scope.data.currentRace.promo.promoPath;
                            event.menu = undefined;

                            GTMFac.GTMEvent().send(
                                $rootScope,
                                "navigation",
                                event
                            );
                        };
                    }

                    _userPromotions();
                })
                .catch(function(err) {
                    $log.error(err.message);
                    // Returns to track hub if raceNumber does not exist
                    var errMessageForInvalidRaceNumber = err.message.includes(
                        "Error getting race"
                    );
                    var errMessageForInvalidRaceNumber2 = err.message.includes(
                        "You must specify a wagerProfile, a trackAbbr and a raceNumber in the options"
                    );
                    if (
                        errMessageForInvalidRaceNumber ||
                        errMessageForInvalidRaceNumber2
                    ) {
                        //eliminates autoplay from url and redirects to track page
                        var path = $location.path().split("/autoplay/true");
                        $scope.$apply(function() {
                            var redirectPathOnInvalidRace;
                            $scope.data.hasRaceData = false;
                            $scope.noActiveRaces = !queryParams.raceNumber;
                            redirectPathOnInvalidRace = $scope.noActiveRaces ? path[0] : "/";
                            $location.url(redirectPathOnInvalidRace);
                        });
                    } else {
                        if (fetchRetry > 0) {
                            $timeout(function() {
                                _getGraphRaceProgramData();
                                fetchRetry--;
                            }, 6000 / fetchRetry);
                        } else {
                            $rootScope.$emit("raceChangedLoaded");
                            $scope.data = {
                                loading: false,
                                currentRace: false
                            };
                            $rootScope.$emit("poller:new-race-data", null);
                            $scope.data.hasRaceData = false;
                        }
                    }
                });
        }

        function _userPromotions() {
            var racePromos = _.get($scope, "data.currentRace.promos", []);
            var useIsPromoTagShownFlag = _.get($rootScope, "activeFeatures.useIsPromoTagShownFlag", false);
            var aboveTheLineRacePromo = null;
            var belowTheLineRacePromo = null;

            for (var i = 0; i < racePromos.length; i++) {
                if (racePromos[i].isAboveTheLine && aboveTheLineRacePromo === null) {
                    aboveTheLineRacePromo = racePromos[i];
                } else if (!racePromos[i].isAboveTheLine && belowTheLineRacePromo === null) {
                    belowTheLineRacePromo = racePromos[i];
                } else if (aboveTheLineRacePromo !== null && belowTheLineRacePromo !== null) {
                    break;
                }
            }

             if ($rootScope.userSession && (aboveTheLineRacePromo || belowTheLineRacePromo)) {
                 var userPromos = UserPromotionsSvc.getUserPromos() || [];
                 $scope.data.hasUserPromo = false;
                 $scope.hasPromoTag = false ;

                 if (Array.isArray(userPromos) && userPromos.length) {
                    for (var i = 0; i < userPromos.length; i++) {
                        if (belowTheLineRacePromo && userPromos[i].id === belowTheLineRacePromo.rootParentPromoID) {
                            var isPromoTagShown = _.get(belowTheLineRacePromo, "isPromoTagShown", false);
                            $scope.data.hasUserPromo = useIsPromoTagShownFlag ? isPromoTagShown : true;
                            $scope.data.currentRace.isUserOptedinPromo = userPromos[i].optedIn;
                            $scope.data.currentRace.promo = belowTheLineRacePromo;
                            break;
                        }

                        if (aboveTheLineRacePromo && userPromos[i].id === aboveTheLineRacePromo.rootParentPromoID && !$scope.data.hasUserPromo) {
                            var isPromoTagShown = _.get(aboveTheLineRacePromo, "isPromoTagShown", false);
                            $scope.data.hasUserPromo = useIsPromoTagShownFlag ? isPromoTagShown : true;
                            $scope.data.currentRace.isUserOptedinPromo = userPromos[i].optedIn;
                            $scope.data.currentRace.promo = aboveTheLineRacePromo;
                        }
                    }
                }
                 $scope.hasPromoTag = $scope.data.hasUserPromo;
             } else {
                $scope.data.hasUserPromo = false;
                $scope.hasPromoTag = false;
                if($scope.data.currentRace){
                    $scope.data.currentRace.promo = aboveTheLineRacePromo;
                    $scope.hasPromoTag = useIsPromoTagShownFlag
                        ? $scope.data.currentRace.promo && $scope.data.currentRace.promo.isPromoTagShown && $scope.data.currentRace.promo.isAboveTheLine
                        : $scope.data.currentRace.promo && $scope.data.currentRace.promo.isAboveTheLine;
                }
            }
        }

        function _notSameRace(oldRace, newRace) {
            var newRaceId = newRace.track.code + "_" + newRace.number;

            if (oldRace.id !== newRaceId) {
                return true;
            }
            return false;
        }

        function _onDataRefresh(data) {
            // The callback is executed twice on a refetch so we need to verify if we are getting the same object
            if (
                $scope.data.currentRace &&
                !angular.equals(data.race, $scope.refreshData)
            ) {
                $scope.refreshData = angular.copy(data.race);

                if (nextRaceBis) {
                    data.race.nextRace = nextRaceBis;
                }

                if (_notSameRace($scope.data.currentRace, $scope.refreshData)) {
                    return;
                }

                var aRace = GraphRaceFac.refreshCurrentRace(
                    $scope.data.currentRace,
                    data
                );

                _updateCurrentRaceData(aRace, $scope.data.currentRace, false);

                if (data.race.lateChanges) {
                    $scope.data.currentRace.changes = RaceInfoFac.changesBuilder(
                        data.race.lateChanges
                    );
                }

                // Stop polling when race status is official
                if (
                    RaceStatusUtils.isStatusRaceOfficial(
                        $scope.data.currentRace.status
                    )
                ) {
                    _unsubscribeGraphRaceProgram();
                }

                $rootScope.$emit("raceChangedLoaded");
            }
        }

        function _onWillPaysRefresh(willPaysData) {
            if (
                $scope.data.currentRace &&
                willPaysData.willPays &&
                willPaysData.number == $scope.data.currentRace.raceNumber &&
                willPaysData.willPays.length > 0
            ) {
                willPaysData.willPays.forEach(function(data, i) {
                    if (!$scope.data.currentRace.willPays) {
                        $scope.data.currentRace.willPays = [];
                    }
                    if (!$scope.data.currentRace.willPays[i]) {
                        $scope.data.currentRace.willPays[i] = {};
                    }

                    $scope.data.currentRace.willPays[i].previousLegResults =
                        data.legResults;
                    $scope.data.currentRace.willPays[i].wagerTypeId =
                        data.type.id;
                    $scope.data.currentRace.willPays[i].wagerAmount =
                        data.wagerAmount;

                    if (data.payouts && data.payouts.length > 0) {
                        $scope.data.currentRace.willPays[
                            i
                        ].payouts = angular.copy(data.payouts);
                        $scope.data.currentRace.willPays[i].payouts.forEach(
                            function(payout) {
                                $scope.data.currentRace.bettingInterests.forEach(
                                    function(bi) {
                                        if (
                                            payout.bettingInterestNumber ==
                                            bi.bettingInterestNumber
                                        ) {
                                            payout.numberColor = bi.numberColor;
                                            payout.saddleColor = bi.saddleColor;
                                        }
                                    }
                                );
                                payout.showPayoff = $filter("currency")(
                                    payout.payoutAmount,
                                    "$"
                                );
                            }
                        );
                    } else {
                        $scope.data.currentRace.willPays[i].payouts = [];
                    }

                    $scope.data.currentRace.willPays[i].description =
                        $filter("currency")(data.wagerAmount, "$") + " ";
                    $scope.data.currentRace.willPays[i].description += data.type
                        ? data.type.name
                        : "";
                    $scope.data.currentRace.willPays[i].description +=
                        " Results: ";

                    if (data.legResults && data.legResults.length > 0) {
                        data.legResults.forEach(function(result) {
                            $scope.data.currentRace.willPays[i].description +=
                                result.winningBi + " | ";
                        });
                    }

                    $scope.data.currentRace.willPays[i].description += "*";
                });

                if (!$scope.data.currentRace.raceOpenForBetting) {
                    _unsubscribeGraphWillPays();
                }
            }
        }

        function _onDataRefreshError(response) {
            $log.error(
                "Error getting data for this race via graph: ",
                response
            );
            $rootScope.$emit("raceChangedLoaded");
        }

        function _subscribeGraphData() {
            var queryParams = {
                trackAbbr: routeParams.trackAbbr,
                raceNumber: routeParams.raceNumber,
                wagerProfile: WagerProfileFac.getSessionOrGenericProfile(),
                queryOptions: {
                    product: window.__TVG_GLOBALS__.PRODUCT,
                    brand: window.__TVG_GLOBALS__.BRAND,
                    withBettingInterests: true,
                    withBiPools: true,
                    withLateChanges: true,
                    withProbables: true,
                    withPools: true
                }
            };
            if (graphSubVolatileData) {
                GraphRaceProgram.refetch(queryParams).catch(function() {});
            } else {
                graphSubVolatileData = GraphRaceProgram.subscribe(
                    queryParams,
                    { success: _onDataRefresh, fail: _onDataRefreshError },
                    graphPollVolatileData
                );
            }

            if (graphSubWillPays) {
                GraphRaceWillPays.refetch(queryParams).catch(function() {});
            } else {
                graphSubWillPays = GraphRaceWillPays.subscribe(
                    queryParams,
                    { success: _onWillPaysRefresh, fail: _onDataRefreshError },
                    graphPollWillPays
                );
            }

            // Prevent that races navigation gets blocked by network pending
            $timeout(function() {
                $rootScope.$emit("raceChangedLoaded");
            }, 10000);
        }

        function _unsubscribeGraphRaceProgram() {
            if (graphSubVolatileData && GraphRaceProgram.isSubscribed()) {
                GraphRaceProgram.unsubscribe(graphSubVolatileData);
                graphSubVolatileData = null;
            }
        }

        function _unsubscribeGraphWillPays() {
            if (graphSubWillPays && GraphRaceWillPays.isSubscribed()) {
                GraphRaceWillPays.unsubscribe(graphSubWillPays);
                graphSubWillPays = null;
            }
        }

        $scope.$on("racePreviousRacePressed", _racePreviousRacePressed);
        $scope.$on("raceNextRacePressed", _raceNextRacePressed);
        $scope.$on("pageTitlePressed", _racesModalListShow);
        $scope.$on("orientationchange", _closeRacesListModal);
        $scope.$on("userPromosUpdate", _userPromotions);

        $scope.$on("$destroy", function() {
            _.forEach(unbind, function(bind) {
                bind();
            });
            _closeRacesListModal();

            $scope.setPageTitleForRace();

            _unsubscribeGraphRaceProgram();
            _unsubscribeGraphWillPays();

            if (graphTimeoutPollData) {
                $timeout.cancel(graphTimeoutPollData);
            }

            if (programPagePoller) {
                $interval.cancel(programPagePoller);
            }
        });

        // Refetch program page and volatile data on login
        $scope.$on("login", function() {
            _pollRaceProgramData();
            _subscribeGraphData();
        });

        unbind.push(
            $rootScope.$on("mtpStatusUpdated", function() {
                $scope.todaysRaceDate = RaceMtpStatusFac.getRaceDate();
                _updateCurrentMtpDisplay($scope.data.currentRace);
            })
        );

        unbind.push(
            $rootScope.$on("race:Changed", function() {
                var trackChanged = false;
                $scope.data.updateShowLiveVideo = true;
                updateShowLiveVideo = true;
                $scope.showAllRaces = false;
                $scope.data.currentRaceHandicappingData = false;
                $scope.hasPromoTag = false;
                routeParams = RaceUrlFac.getRacesInfoFromRouteParams();

                // Verify if track has changed
                if (
                    !$scope.data.currentRace ||
                    $scope.data.currentRace.trackAbbr !== routeParams.trackAbbr
                ) {
                    $scope.data.loading = true;
                    $scope.videoPopout = false;
                    trackChanged = true;
                }

                // Refresh RaceProgram data for new race from Graph
                $scope.data.loading = true;
                _pollRaceProgramData();

                if ($scope.$root && !$scope.$root.$$phase) {
                    $scope.$apply();
                }
            })
        );

        unbind.push(
            $rootScope.$on("startVideoCtrl", function(event, data) {
                if ($scope.data.currentRace) {
                    _configUpdateVideoState(data, true);
                    $scope.data.showLiveVideo =
                        $scope.data.currentRace.showLiveVideo;
                }
            })
        );

        unbind.push(
            $rootScope.$on("playingVideo", function(event, data) {
                _configUpdateVideoState(data, false);
            })
        );

        function _configUpdateVideoState(raceData, value) {
            if (
                raceData.trackAbbr === $scope.data.currentRace.trackAbbr &&
                raceData.raceNumber === $scope.data.currentRace.raceNumber
            ) {
                $scope.data.updateShowLiveVideo = value;
                updateShowLiveVideo = value;
            }
        }

        function _racesModalListShow(message) {
            $scope.data.infoMessage += message;
            confirmDialog = $uibModal.open({
                appendTo: FindModalContainerFac("#angular-app"),
                backdropClass: "tvgmodal-backdrop",
                windowTemplateUrl:
                    "js/common/common/modules/Utilities/templates/modal-window.html",
                windowClass: "login-error",
                templateUrl:
                    "js/desktop/modules/Races/templates/track-race-list-modal.html",
                scope: $scope
            });
        }

        function _closeRacesListModal() {
            if (confirmDialog) {
                confirmDialog.close();
                confirmDialog = null;
            }
        }

        function _cleanQueryParameters() {
            /* we need to check and clean query parameters which might be present in our location.url, so we can guarantee that the
                 next races are not with previous selections */
            if ($location.path() !== $location.url()) {
                $location.url($location.path());
            }
        }

        function _racePreviousRacePressed() {
            _cleanQueryParameters();
            routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
            for (var i = 0; i < ($scope.data.races || {}).length; i++) {
                var race = $scope.data.races[i];

                if (race.raceNumber == parseInt(routeParams.raceNumber) - 1) {
                    $location.url(
                        RaceUrlFac.calculateRacePath(
                            race.trackAbbr,
                            race.trackName,
                            race.raceNumber
                        )
                    );
                    break;
                }
            }
        }

        function _raceNextRacePressed() {
            routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
            _cleanQueryParameters();

            for (var i = 0; i < ($scope.data.races || {}).length; i++) {
                var race = $scope.data.races[i];

                if (race.raceNumber == parseInt(routeParams.raceNumber) + 1) {
                    if (parseInt(race.raceNumber) > 1) {
                        $scope.setShowPreviousTitleArrow(true);
                    } else {
                        $scope.setShowPreviousTitleArrow(false);
                    }

                    if (parseInt(race.raceNumber) < $scope.data.races.length) {
                        $scope.setShowNextTitleArrow(true);
                    } else {
                        $scope.setShowPreviousTitleArrow(false);
                    }

                    $location.url(
                        RaceUrlFac.calculateRacePath(
                            race.trackAbbr,
                            race.trackName,
                            race.raceNumber
                        )
                    );
                    break;
                }
            }
        }

        /**
         * Parse race program data fetched by pollers
         *
         * 1) mark opened and cancelled races
         * 2) filter allowed races
         * 3) sort races by raceNumber
         * 4) save parsed races in scope
         * 5) update current race data and arrows
         *
         * @param  {Array} data  Raw races data
         */
        function _fetchPollingData(data, fromGraph) {
            var races = data;
            var race;

            fromGraph = fromGraph || false;

            // get updated route parameters
            routeParams = RaceUrlFac.getRacesInfoFromRouteParams();

            // render loaders
            $scope.data.loading = false;
            $scope.setShowPreviousTitleArrow(false);
            $scope.setShowNextTitleArrow(false);

            if (fromGraph) {
                races = [data];
                race = data;

                race.raceOpenForBetting = RaceStatusUtils.isRaceOpenForBetting(
                    race
                );
                race.raceOpenForLiveVideo = RaceStatusUtils.isRaceOpenForLiveVideo(
                    race
                );
                race.raceCancelled = RaceStatusUtils.isStatusManuallyClosed(
                    race.status
                );

                $scope.data.races = new Array(race);

                if (!$scope.showAllRaces) {
                    if (race.id === routeParams.raceId) {
                        _updateCurrentRaceArrows(races, race, 0);
                        _updateCurrentRaceData(race, 0, !fromGraph);
                        _updateCurrentMtpDisplay(race);

                        if (
                            $rootScope.activeFeatures.tvgPickProgramPageResults
                        ) {
                            _updateTalentPicksData(race);
                        }
                    }
                }
            } else {
                // stop
                // (should the loader stay active?)
                if (!Array.isArray(races) || !races.length) {
                    return;
                }

                // 1) mark opened and cancelled races
                races = races.map(function(race) {
                    race.raceOpenForBetting = RaceStatusUtils.isRaceOpenForBetting(
                        race
                    );
                    race.raceOpenForLiveVideo = RaceStatusUtils.isRaceOpenForLiveVideo(
                        race
                    );
                    race.raceCancelled = RaceStatusUtils.isStatusManuallyClosed(
                        race.status
                    );
                    return race;
                });

                // 2) sort races by raceNumber
                races = _sortRacesByRaceNumber(races);

                // 3) save parsed races in scope
                $scope.data.races = races;

                // 4) update current race data and arrows
                if (!$scope.showAllRaces) {
                    races.forEach(function(race, i) {
                        if (race.id === routeParams.raceId) {
                            _updateCurrentRaceArrows(races, race, i);
                            _updateCurrentRaceData(race, i, !fromGraph);
                            _updateCurrentMtpDisplay(race);

                            if (
                                $rootScope.activeFeatures
                                    .tvgPickProgramPageResults
                            ) {
                                _updateTalentPicksData(race);
                            }
                        }
                    });
                }
            }
        }

        function _updateCurrentRaceArrows(races, race, i) {
            var raceNumber = parseInt(race.raceNumber, 10);
            var isFirst = i > 0;
            var isLast = raceNumber === races.length;

            $scope.setShowPreviousTitleArrow(!isFirst);
            $scope.setShowNextTitleArrow(!isLast);
        }

        /**
         * Filter races according to rootScope.allowedRaces
         *
         * @param {Array} races  Races to filter
         * @return {Array}       Allowed races
         */
        function _filterAllowedRaces(races) {
            var filtered = races;
            var allowedRaces = $rootScope.allowedRaces || [];
            var allowedMap = {};

            // @TODO this was the original implemented behaviour, but I think this
            // may lead to the same race to be available for some seconds and after
            // allowed races being loaded, it may disappear
            // (there may be some race conditions problems here)

            // stop, because allowed races are not loaded yet
            if (!allowedRaces.length) {
                return filtered;
            }

            // create a map for allowed races' IDs
            ($rootScope.allowedRaces || []).forEach(function(race) {
                allowedMap[race.id] = true;
                return this;
            });

            // filter only races
            // that are in rootScope allowed races array or
            // that don't have "open" or similar status
            filtered = (races || []).filter(function(race) {
                var status = race.status || "";
                var isAllowed = allowedMap[race.id];
                var isStatusOk = true;

                if (!isAllowed) {
                    // isStatusOk = !status.match(/^(o|ic|mo)$/i)
                    isStatusOk =
                        !RaceStatusUtils.isStatusOpen(status) &&
                        !RaceStatusUtils.isStatusUpNext(status) &&
                        !RaceStatusUtils.isStatusManuallyOpened(status);
                }

                return isAllowed || isStatusOk;
            });

            return filtered;
        }

        /**
         * Sort races by race number
         *
         * @param {Array} races  Races to sort
         * @return {Array}       Sorted races
         */
        function _sortRacesByRaceNumber(races) {
            return (races || []).sort(function(a, b) {
                if (!a || !b) {
                    return 0;
                }

                a = parseInt(a.raceNumber);
                b = parseInt(b.raceNumber);

                return a - b;
            });
        }

        function _updateCurrentMtpDisplay(aRace) {
            var raceMtpStatusValue;

            if (aRace) {
                raceMtpStatusValue = RaceMtpStatusFac.getRaceMtpStatus(
                    aRace.trackAbbr + "_" + aRace.raceNumber
                );

                if (raceMtpStatusValue && raceMtpStatusValue.mtpDisplay) {
                    $scope.mtpDisplay = raceMtpStatusValue.mtpDisplay;
                } else {
                    $scope.mtpDisplay = new MtpDisplay(aRace.postTime);
                }
            }
        }

        function _updateCurrentRaceData(
            aRace,
            aRaceIndex,
            requestHandicapping
        ) {
            var isTheSameRace = ($scope.data.currentRace || {}).id === aRace.id;

            $scope.data.handicapLoading = true;
            $scope.data.currentRace = isTheSameRace ? Object.assign($scope.data.currentRace || {}, aRace) : aRace;
            $scope.data.myBetsCurrentRaceInput = MyBetsCurrentRaceInputBuilder()
                .withTrackAbbr(aRace.trackAbbr)
                .withPerfAbbr(aRace.perfAbbr)
                .withRaceStatus(aRace.status)
                .withRaceNumber(aRace.raceNumber)
                .withTrackName(aRace.trackName)
                .withPostTime(aRace.postTime)
                .withTvgRaceId(aRace.tvgRaceId)
                .build();

            $scope.$broadcast("RaceChanges:CurrentRaceUpdated", aRace.changes);
            $scope.data.currentRaceIndex = aRaceIndex;
            $scope.setPageTitleForRace(aRace);

            $scope.data.raceOpenForBetting = RaceStatusUtils.isRaceOpenForBetting(
                aRace
            );
            $scope.data.raceOpenForLiveVideo = RaceStatusUtils.isRaceOpenForLiveVideo(
                aRace
            );
            $scope.data.raceCancelled = RaceStatusUtils.isStatusManuallyClosed(
                aRace.status
            );

            if (updateShowLiveVideo) {
                $scope.data.showLiveVideo = aRace.showLiveVideo;
            }

            if (!isTheSameRace) {
                ProgramPageRunnersFac.clearWhenChangingRace();
                $scope.hasPromoTag = false;
            }

            ProgramPageRunnersFac.updateCurrentRace(aRace);

            // Create race replay input for tvgvideo component when race is over.
            if (
                !$scope.data.raceOpenForLiveVideo &&
                $scope.data.currentRace.hasReplays &&
                $scope.data.currentRace.flashAvailable &&
                $scope.data.currentRace.replayFileName
            ) {
                var raceDate = $scope.todaysRaceDate;
                var postTimeDate = new Date(aRace.postTime);

                $scope.data.raceReplayInputData = RaceVideoFac.buildRaceReplayInputData(
                    raceDate,
                    postTimeDate,
                    aRace.trackAbbr,
                    aRace.raceNumber,
                    aRace.perfAbbr,
                    false,
                    aRace.replayCode,
                    aRace.replayFileName
                );
            }

            //force new race update
            $timeout(function() {
                $scope.$apply();
            });
        }

        function _updateTalentPicksData(aRace) {
            RaceInfoFac.getTalentPicks(aRace.id).then(function(data) {
                _reloadTalentPick(data);
                $rootScope.$emit("talentPicks:update");
            });
        }

        function _reloadTalentPick(data) {
            $scope.data.talentPicks = [];
            data.forEach(function(elem) {
                TalentPicksEventsFactory.setRaceStatusPropertiesOfTalentPick(
                    elem,
                    elem.raceStatus
                );
                $scope.data.talentPicks.push(elem);
            });
        }

        (function init() {
            $scope.showAllRaces = false;
            $scope.$on("showAllRaces", function(event, data) {
                $scope.showAllRaces = data;
            });
        })();
    }

    RaceProgramCtrl.$inject = [
        "$scope",
        "RaceUrlFac",
        "RaceInfoFac",
        "RaceVideoFac",
        "$q",
        "$route",
        "$location",
        "$uibModal",
        "$rootScope",
        "GTMFac",
        "METADATA",
        "ProgramPageRunnersFac",
        "TalentPicksEventsFactory",
        "GraphRaceProgram",
        "GraphRaceWillPays",
        "GraphRaceFac",
        "RaceMtpStatusFac",
        "WagerProfileFac",
        "VideoPopOutFac",
        "GraphRaceBettingInterests",
        "FindModalContainerFac",
        "$interval",
        "$timeout",
        "$filter",
        "$log",
        "UserPromotionsSvc"
    ];

    return RaceProgramCtrl;
});

