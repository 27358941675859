"use strict";

define('AppRootFac',["lodash", "moment", "Mediator"], function (_, moment, mediator) {
    AppRootFac.$inject = [
        "$rootScope",
        "ConfigurationFac",
        "ContentFac",
        "LayoutFac",
        "FeaturesHandlerSvc",
        "$uibModalStack",
        "$location",
        "$window",
        "CMSCONTENT",
        "CookieFac",
        "WithdrawFac",
        "GTMFac",
        "NewWindowGridFac",
        "$filter",
        "$document",
        "$timeout",
        "SecuritySvc",
        "$routeParams",
        "RegistrationPageGTMFac",
        "RacesPageGTMFac",
        "$cookies",
        "WagerProfileFac",
        "UserPromotionsSvc",
        "LocalStorageFac",
        "UserProfileSvc"
    ];

    function AppRootFac(
        $rootScope,
        ConfigurationFac,
        ContentFac,
        LayoutFac,
        FeaturesHandlerSvc,
        $uibModalStack,
        $location,
        $window,
        CMSCONTENT,
        CookieFac,
        WithdrawFac,
        GTMFac,
        NewWindowGridFac,
        $filter,
        $document,
        $timeout,
        SecuritySvc,
        $routeParams,
        RegistrationPageGTMFac,
        RacesPageGTMFac,
        $cookies,
        WagerProfileFac,
        UserPromotionsSvc,
        LocalStorageFac,
        UserProfileSvc
    ) {
        var doneUserGTM = false;
        var userSessionUnwatch;
        var GTMUserLoggedOutUnwatch;

        // Configuration needs to be set again when angular app boots
        ConfigurationFac.setApplicationContextItem("product", productContext);
        ConfigurationFac.setApplicationContextItem(
            "application",
            applicationContext
        );
        ConfigurationFac.setApplicationContextItem("location", locationContext);

        //rootScope config variables
        $rootScope.isIE = document.documentMode !== undefined; //Check if the current browser is the IE.
        $rootScope.activeFeatures = {};
        $rootScope.enableGTM = true;
        $rootScope.appName = "TVG";
        $rootScope.allowedRaces = [];
        $rootScope.uniqueVideoId = 0;
        $rootScope.withdrawData = {
            haveRequestedInfo: false,
            pendingListWithdraw: [],
        };
        $rootScope.locationContext = locationContext;
        $rootScope.productContext = "tvg4";

        $rootScope.redirectSite =
            ConfigurationFac.getBaseApiUrl() + "/backtodesktop";

        // Location overrides
        if (locationContext == "nj") {
            $rootScope.appName = "4NJBets";
            $rootScope.product = $rootScope.product + locationContext;
        }

        if ($location.path() === "/deposit") {
            $rootScope.showAccountPanel = true;
        }

        ContentFac.setCMSContent(CMSCONTENT);
        ContentFac.restoreCache(cmsCache);

        // video player map object
        $rootScope.playerMap = {};

        $rootScope.appReadyFired = false;
        $rootScope.hasSessionRequested = false;
        $rootScope.hasNeverLogin = true;
        $rootScope.appReady = {};

        $rootScope.hideAppContents = function () {
            if (/promos/.test($location.path())) {
                return true;
            }

            // Disable my bets angular JS template
            if (
                $location.path().includes("my-bets") &&
                $rootScope.activeFeatures.myBetsDesktopMicroApp
            ) {
                return true;
            }

            if (
                $location.path().includes("racetracks") &&
                _.get($rootScope, "activeFeatures.enableSeoRaceTracks", false)
            ) {
                var raceNumber = _.get($location.search(), "race", null);
                return !raceNumber;
            }

            switch ($location.path()) {
                case "/wager-rewards":
                case "/":
                case "/login":
                case "/dev-login":
                case "/preferences":
                case "/promos/":
                case "/registration":
                case "/referral":
                case "/responsible-gaming":
                case "/credentials-reset":
                case "/unsubscribe":
                case "/wallet": {
                    return true;
                }
                default:
                    return false;
            }
        };

        function _initTVG4() {
            FeaturesHandlerSvc.init();

            $rootScope.showNextRacesBar = _showNextRacesBar();
            $rootScope.showSiteTabs = _showSiteTabs();
            $window.doNotSellLink = $filter("CMSLabels")("doNotSellLink");

            $rootScope.appReady.carousel = false;
            $rootScope.appReady.featuredTracks = false;
            $rootScope.appReady.tvgPicks = false;
            $rootScope.appReady.session = false;
            if ($rootScope.activeFeatures.carryOverPools) {
                $rootScope.appReady.carryoverPools = false;
            }

            if ($rootScope.activeFeatures.openBetWindowsInGrid) {
                $window.goToDeposit = _goToDeposit;
                NewWindowGridFac.windowFunctions($window);
            }

            ConfigurationFac.setApplicationContextItem("device", "desktop");

            applicationContext =
                $rootScope.productContext === "tvg4"
                    ? "desktop"
                    : applicationContext;
            $rootScope.cssFile = $rootScope.productContext;

            // Verify if graphql features are enabled across the application
            $rootScope.grayhoundRace = false;
            $rootScope.shouldShowWallet = $rootScope.activeFeatures
                .enablePawsAbTesting
                ? $cookies.get("paws_wallet") === "true"
                : true;
            $rootScope.homepageBeta =
                $cookies.get("betahome") || locationContext === "nj";

            angular
                .element(document)
                .find("#angular-app")
                .removeClass("loading");

            _gtmSessionWatchers();
            _setRFRCookie();
            _handleWindowMessages();
        }

        function _handleWindowMessages() {
            window.addEventListener("message", function (event) {
                var eventType = _.get(event, "data.type", "");

                switch (eventType) {
                    case "open_url":
                        var url = _.get(event, "data.payload", "");
                        var carriageReturnOrNewlinePattern = /[\r\n]/g;
                        var validUrlPattern = /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i;
                        // Eventually clean the url from carriage return or newline
                        var cleanedUrl = url.replace(carriageReturnOrNewlinePattern, "");

                        // Check if matches the valid URL pattern
                        if (!validUrlPattern.test(cleanedUrl)) {
                          cleanedUrl = "";
                        }
                        window.open(cleanedUrl, "_blank");
                        break;
                    default:
                        return;
                }
            });
        }

        function _redirectFromPaymentMethod(path) {
            if (_.includes(path, "activeTab=sightline")) {
                return {
                    tla: "SLN",
                    menutab: getActiveMenuTab(path),
                    fullname: "Sightline",
                };
            }

            if (_.includes(path, "activeTab=paypal")) {
                return {
                    tla: "PAY",
                    menutab: getActiveMenuTab(path),
                    fullname: "Paypal",
                };
            }

            return {};
        }

        /* ----- RootScope Functions ----- */

        function _fnGo() {
            $rootScope.go = function (path, param) {
                param = typeof param !== "undefined" ? param : false;

                $timeout(function () {
                    if (param) {
                        $location.path(path).search(param);
                    } else {
                        $location.path(path);
                    }
                });

                if (path === "joinnow") {
                    $rootScope.firstTimeJoinNow = true;
                }
            };
        }

        function _getNextRaceUrl() {
            $rootScope.getNextRaceUrl = function () {
                if (
                    $routeParams.trackAbbr &&
                    $routeParams.perfAbbr &&
                    $routeParams.race
                ) {
                    return (
                        "/race/" +
                        $routeParams.trackAbbr +
                        "/" +
                        $routeParams.perfAbbr +
                        "/" +
                        (parseInt($routeParams.race) + 1)
                    );
                }
                return "";
            };
        }

        function _getPreviousRaceUrl() {
            $rootScope.getPreviousRaceUrl = function () {
                if (
                    $routeParams.trackAbbr &&
                    $routeParams.perfAbbr &&
                    $routeParams.race
                ) {
                    return (
                        "/race/" +
                        $routeParams.trackAbbr +
                        "/" +
                        $routeParams.perfAbbr +
                        "/" +
                        (parseInt($routeParams.race) - 1)
                    );
                }
                return "";
            };
        }

        function _fnPreviousButtonPressed() {
            $rootScope.previousButtonPressed = function (event) {
                if (event) {
                    event.preventDefault();
                }
                $rootScope.$broadcast("racePreviousRacePressed");
            };
        }

        function _isCustomerSupportHeader(route) {
            return route &&
                route.originalPath.indexOf("forgot-credentials") !== -1 &&
                $rootScope.activeFeatures.loginPRFv2
                ? false
                : route && route.customerSupportHeader === true;
        }

        function _showHeaderTitle(route) {
            return route && route.showheadertitle === true;
        }

        function _isPRFMicroAppBasedOnRoute(route) {
            return (
                route &&
                route.isPRFMicroApp === true &&
                route.originalPath.indexOf("/forgot-credentials") !== -1 &&
                !$rootScope.activeFeatures.loginPRFv2
            );
        }

        function _showNextRacesBar(route) {
            var hideNextRaces =
                route && route.hideNextRaces ? route.hideNextRaces : false;
            return !hideNextRaces && !_isCustomerSupportHeader(route);
        }

        function _showSiteTabs(route) {
            var hostname = $location.host();

            return (
                $rootScope.activeFeatures.showSiteTabs &&
                /4njbets/gi.test(hostname)
            );
        }

        function _fnRaceButtonPressed() {
            $rootScope.raceButtonPressed = function () {
                $rootScope.$broadcast("pageTitlePressed");
            };
        }

        function _fnNextButtonPressed() {
            $rootScope.nextButtonPressed = function (event) {
                if (event) {
                    event.preventDefault();
                }
                $rootScope.$broadcast("raceNextRacePressed");
            };
        }

        function _fnRefreshListPendingWithdraw() {
            $rootScope.refreshListPendingWithdraw = function () {
                WithdrawFac.withdrawPendingList().then(
                    function (data) {
                        $rootScope.withdrawData.pendingListWithdraw = data;
                        $rootScope.hasPendingWithdrawals =
                            _.isArray(data) && data.length > 0;
                    },
                    function () {
                        $rootScope.withdrawData.pendingListWithdraw = [];
                        $rootScope.hasPendingWithdrawals = false;
                    }
                );
            };
        }

        function _fnGetLocationPath() {
            $rootScope.getLocationPath = function () {
                return $location.url();
            };
        }

        function _fnReloadApp() {
            $rootScope.reloadApp = function () {
                var maintenanceParams = {};
                maintenanceParams.gaEventLabel = "";
                maintenanceParams.eventLabel = "RetryButton";
                maintenanceParams.gaEventAction = "Retry Button";

                _sendGTMMaintenanceError(maintenanceParams);
                CookieFac.setCookie("reloadcookie", true);

                $window.location.reload();
            };
        }

        /* ----- RootScope Listeneners ----- */

        function _onUser() {
            $rootScope.$on("user", function () {
                UserPromotionsSvc.startPromotionsPoller();
                $rootScope.hasNeverLogin = !CookieFac.getCookie("hasLoginOnce");
                if ($location.path() == "/joinnow") {
                    $timeout(function () {
                        $location.path("/");
                    });
                }

                var userAuthenticatedsState = LocalStorageFac.getItem(
                    "userAuthenticationState"
                );

                if (userAuthenticatedsState === "CT") {
                    $rootScope.showConnecticutDisclaimer = true;
                } else {
                    $rootScope.showConnecticutDisclaimer = false;
                }
            });
        }

        function _onLocationChangeStart() {
            var redirectFromPaymentObj;

            $rootScope.$on(
                "$locationChangeStart",
                function (event, newLocation) {
                    var url = $location.url();
                    redirectFromPaymentObj = _redirectFromPaymentMethod(url);
                    // Scroll to top
                    _scrollToTop();

                    // On mobile videos need to be stopped to not crash application
                    if (applicationContext === "mobile") {
                        _stopVideos();
                    }

                    if ($rootScope.userSession !== undefined || doneUserGTM) {
                        _launchGTMEvent();
                    }

                    // this prevents the session expired popup alert not to be dismissed when
                    // the session expires and we redirect the user to the home route
                    if ($location.hash() !== "sessionExpired") {
                        // other than that, always dismiss all modals when changing routes
                        $uibModalStack.dismissAll();
                    }

                    var route = $location.path().split("/")[1];

                    $rootScope.hasNeverLogin =
                        !CookieFac.getCookie("hasLoginOnce");
                    $rootScope.logoutOk = true;
                    $rootScope.loginOk = true;
                    $rootScope.isLandingPageLocation = !!(
                        route == "joinnow" || route == "landingpage"
                    );

                    $rootScope.pageTitle = "";
                    $rootScope.pageTitleRaceMobile = "";
                    $rootScope.pageTitleTrackNameMobile = "";
                    $rootScope.pageSubTitle = "";
                    $rootScope.moreDetails = "";

                    if (
                        ($location.path() === "/registration" ||
                            $location.path() === "/signup") &&
                        !$location.host().includes("fanduel") &&
                        !$location.host().includes("fanduel.com")
                    ) {
                        event.preventDefault();

                        var rcodeQuery = _getQueryStringParam("rcode");
                        var promocodeQuery = _getQueryStringParam("promocode");
                        var rfrQuery = _getQueryStringParam("rfr");

                        if (rcodeQuery) {
                            window.location.assign(
                                $location.path() + "?rcode=" + rcodeQuery
                            );
                        } else if (promocodeQuery) {
                            window.location.assign(
                                $location.path() +
                                    "?promocode=" +
                                    promocodeQuery
                            );
                        } else if (rfrQuery) {
                            window.location.assign(
                                $location.path() + "?rfr=" + rfrQuery
                            );
                        } else {
                            window.location.assign("/registration");
                        }
                    }

                    $rootScope.isGreyhoundRace = route === "greyhounds";

                    if (!_.isEmpty(redirectFromPaymentObj)) {
                        $rootScope.activeOption = {
                            tla: redirectFromPaymentObj.tla,
                            isPaymentConfirmation: _isPaymentConfirmation(url),
                            userCancelled: _userCancelled(url),
                        };
                        $rootScope.$emit(
                            "redirectFrom".concat(
                                redirectFromPaymentObj.fullname
                            ),
                            redirectFromPaymentObj.menutab,
                            $rootScope.activeOption.isPaymentConfirmation,
                            $rootScope.activeOption.userCancelled
                        );
                    } else {
                        $rootScope.activeOption = undefined;
                    }

                    var userAuthenticatedsState = LocalStorageFac.getItem(
                        "userAuthenticationState"
                    );

                    if (userAuthenticatedsState === "CT") {
                        $rootScope.showConnecticutDisclaimer = true;
                    } else {
                        $rootScope.showConnecticutDisclaimer = false;
                    }
                }
            );
        }

        function _isPaymentConfirmation(url) {
            return _.includes(url, "&isPaymentConfirmation=true");
        }

        function _userCancelled(url) {
            return url.indexOf("&userCancelled=true") > -1;
        }

        function getActiveMenuTab(url) {
            var endIndex;
            var qsInit;
            var qsFinal;

            // TODO: build a decent querystring getter
            endIndex = url.indexOf($location.hash()) || url.length;
            qsInit = url.substr(url.indexOf("?") + 1, endIndex);
            qsFinal = _.includes(qsInit, "%20")
                ? qsInit.split("%20")[1]
                : qsInit.split("+")[1];

            if (_.includes(qsFinal, "&")) {
                qsFinal = qsFinal.substr(0, qsFinal.indexOf("&"));
            }

            return qsFinal || "";
        }

        function _onLocationChangeSuccess() {
            $rootScope.$on("$locationChangeSuccess", function () {
                // Scroll to top
                _scrollToTop();
            });
        }

        function _onRouteChangeStart() {
            $rootScope.$on(
                "$routeChangeStart",
                function (event, next, current) {
                    // Redirect to home if route only allows logged in
                    var redirectHome = function (data) {
                        $rootScope.hasRequestedSession = true;
                        $timeout(function () {
                            !data.value && $location.path("/");
                        });
                        mediator.unsubscribe("sessionValid", redirectHome);
                    };
                    // Redirect to home if is logged in accessing registration
                    var redirectHomeOnRegistration = function (data) {
                        $rootScope.hasRequestedSession = true;
                        $timeout(function () {
                            data.value && $location.path("/");
                        });
                        mediator.unsubscribe(
                            "sessionValid",
                            redirectHomeOnRegistration
                        );
                    };
                    // Redirect to home if wager profile is not greyhounds allowed or not logged in
                    var redirectHomeGreyhounds = function (data) {
                        if (
                            !data.value ||
                            !_validateGreyhoundPathAgainstWagerProfile()
                        ) {
                            $timeout(function () {
                                $rootScope.isGreyhoundRace = false;
                                $location.path("/");
                            });
                            mediator.unsubscribe(
                                "sessionValid",
                                redirectHomeGreyhounds
                            );
                        }
                    };
                    // Redirect to my stable if is logged through the email opt out screen
                    var redirectMyStable = function (data) {
                        $rootScope.hasRequestedSession = true;
                        $timeout(function () {
                            data.value && $location.path("/my-stable");
                        });
                        mediator.unsubscribe("sessionValid", redirectMyStable);
                    };

                    // Validate routes if session has not been requested
                    if (!$rootScope.hasRequestedSession) {
                        if (next.needsLogIn === true) {
                            if (
                                next.originalPath !== "/wager-rewards" ||
                                (next.originalPath === "/wager-rewards" &&
                                    !$rootScope.activeFeatures.wagerRewardsApp)
                            ) {
                                mediator.subscribeWithPast(
                                    "sessionValid",
                                    redirectHome
                                );
                            }
                        }

                        if (next.needsLogIn === true) {
                            if (
                                next.originalPath !== "/promos" ||
                                (next.originalPath === "/promos" &&
                                    !$rootScope.activeFeatures
                                        .enablePromosStoryblok)
                            ) {
                                mediator.subscribeWithPast(
                                    "sessionValid",
                                    redirectHome
                                );
                            }
                        }

                        if (next.originalPath === "/registration") {
                            mediator.subscribeWithPast(
                                "sessionValid",
                                redirectHomeOnRegistration
                            );
                        }
                        if (
                            next.originalPath &&
                            next.originalPath.indexOf("/greyhounds") === 0
                        ) {
                            $rootScope.isGreyhoundRace = true;
                            mediator.subscribeWithPast(
                                "sessionValid",
                                redirectHomeGreyhounds
                            );
                        }

                        if (
                            next.params &&
                            next.params.redirect &&
                            next.params.redirect === "mystable"
                        ) {
                            $rootScope.shouldRedirectToMyStable = true;
                            mediator.subscribeWithPast(
                                "sessionValid",
                                redirectMyStable
                            );
                        }
                    } else {
                        // Validate routes after session has been requested
                        if (
                            next.originalPath &&
                            next.originalPath.indexOf("/greyhounds") === 0
                        ) {
                            $rootScope.isGreyhoundRace = true;
                            if (
                                !$rootScope.user ||
                                !_validateGreyhoundPathAgainstWagerProfile()
                            ) {
                                $timeout(function () {
                                    $rootScope.isGreyhoundRace = false;
                                    $location.path("/");
                                });
                            }
                        }
                    }

                    // Scroll to top
                    _scrollToTop();

                    if (angular.isString(next.toggleKey)) {
                        var isToggleOff =
                            $rootScope.activeFeatures[next.toggleKey] === false;

                        if (isToggleOff) {
                            $timeout(function () {
                                $location.path("/");
                            });
                        }
                    }

                    if (
                        current &&
                        next &&
                        next.originalPath !== current.originalPath &&
                        next.originalPath !== current.redirectTo &&
                        next.title !== "Races" &&
                        next.title !== "Results"
                    ) {
                        $rootScope.appTitle = next.title;
                    } else {
                        $rootScope.appTitle = next.title || "Home Screen";
                        $rootScope.pageReload = current ? true : false;
                        if (
                            next.title === "Races" &&
                            $rootScope.layout !== "desktop"
                        ) {
                            if (next.pathParams.raceNumber) {
                                $rootScope.appTitle =
                                    "Races | Free Handicapping";
                            } else {
                                $rootScope.appTitle = "Races | By Time";
                            }
                        }
                        if (next.title === "Results") {
                            $rootScope.appTitle = "Results | By Time";
                        }
                    }

                    var route = $location.path().split("/")[1];
                    if (
                        (route === "login" || route === "dev-login") &&
                        productContext === "tvg4"
                    ) {
                        if (
                            !$rootScope.user ||
                            !$rootScope.user.accountNumber
                        ) {
                            $rootScope.$broadcast(
                                "callLoginRequiredModal",
                                handleMyStableRedirect.bind(
                                    null,
                                    redirectMyStable
                                )
                            );
                        } else {
                            handleMyStableRedirect(redirectMyStable);
                        }

                        var gtmEvent = {
                            event: "ga_pageload",
                            screenName: "Login Page **",
                            sectionName: "Login | Login Page",
                        };

                        GTMFac.GTMEvent().send($rootScope, "", gtmEvent);
                    }

                    if (
                        route === "forgot-credentials" &&
                        productContext === "tvg4"
                    ) {
                        $rootScope.$broadcast("callForgotCredentialsModal");
                    }

                    if (
                        route === "recover-email" &&
                        productContext === "tvg4" &&
                        $rootScope.activeFeatures.loginPRFv2
                    ) {
                        $rootScope.$broadcast("callRecoverEmailModal");
                    }

                    if (route === "my-funds") {
                        handleMyFundsToWalletRedirect();
                    }

                    // Session event needs to be sent in order to ensure the services who depend on the session are initialized
                    $timeout(function () {
                        $rootScope.$broadcast("session");
                    });

                    $rootScope.pageLoadedFired = false;
                }
            );
        }

        function handleMyFundsToWalletRedirect() {
            var hasInitialTab = !!$location.search().initialTab;

            // If pawsWalletAvailable is true and there is no initialTab query param, redirect to wallet
            if (
                $rootScope.activeFeatures.pawsWalletAvailable &&
                $rootScope.shouldShowWallet &&
                hasInitialTab === false
            ) {
                $timeout(function () {
                    $location.path("/wallet");
                });
                return;
            }
        }

        function handleMyStableRedirect(redirectMyStable) {
            if ($rootScope.shouldRedirectToMyStable)
                redirectMyStable({ value: true });
        }

        function _validateGreyhoundPathAgainstWagerProfile() {
            var userWP = WagerProfileFac.getSessionOrGenericProfile();
            var wpList = [];

            try {
                var jsonList = JSON.parse(
                    $filter("CMSValue")("greyhoundsProfiles")
                );
                wpList = jsonList.profilesList || [];
            } catch (e) {
                wpList = [];
            }

            return _.indexOf(wpList, userWP) >= 0;
        }

        function _onRouteChangeSuccess() {
            $rootScope.$on("$routeChangeSuccess", function (event, current) {
                //show Hide Next Races Bar
                $rootScope.fullscreenPage =
                    current && current.fullscreenPage
                        ? current.fullscreenPage
                        : false;
                $rootScope.showNextRacesBar = _showNextRacesBar(current);
                $rootScope.customerSupportHeader =
                    _isCustomerSupportHeader(current);
                $rootScope.showHeaderTitle = _showHeaderTitle(current);
                $rootScope.isPRFMicroApp = _isPRFMicroAppBasedOnRoute(current);
                $rootScope.inHomepage =
                    $location.path() === "home" ||
                    $location.path() === "/" ||
                    $location.path() === "";

                if (
                    current &&
                    typeof current.$$route !== "undefined" &&
                    current.$$route.hasOwnProperty("hasTopBarNavigation")
                ) {
                    $rootScope.hasTopBarNavigation =
                        current.$$route.hasTopBarNavigation;
                } else {
                    $rootScope.hasTopBarNavigation = false;
                }

                if (
                    $location.path() === "/videos" ||
                    $location.path() === "/races" ||
                    $location.path() === "/results"
                ) {
                    $rootScope.pageTitle = current.title;
                }

                $rootScope.fullContentPage =
                    current && current.fullContentPage
                        ? current.fullContentPage
                        : false;

                $rootScope.hideHeader =
                    current && current.hideHeader ? current.hideHeader : false;

                $rootScope.hideFooter =
                    current && current.hideFooter ? current.hideFooter : false;

                // Scroll to top
                _scrollToTop();

                //Add route change event
                mediator.dispatch("UPDATE_ROUTER", {
                    payload: $location.path(),
                });
            });
        }

        function _onScrollToTop() {
            $rootScope.$on("scrollToTop", function () {
                _scrollToTop();
            });
        }

        function _onLogin() {
            $rootScope.$on("login", function () {
                ConfigurationFac.setApplicationContextItem(
                    "user_status",
                    "logged_in"
                );
                var cmsCache = ContentFac.retrieveCache();
                if (cmsCache.hasOwnProperty("LandingPages")) {
                    delete cmsCache.LandingPages;
                }
                ContentFac.restoreCache(cmsCache);

                $rootScope.$broadcast("afterLogin", true);

                $rootScope.hasNeverLogin = !CookieFac.getCookie("hasLoginOnce");

                var userAuthenticatedsState = LocalStorageFac.getItem(
                    "userAuthenticationState"
                );

                if (userAuthenticatedsState === "CT") {
                    $rootScope.showConnecticutDisclaimer = true;
                } else {
                    $rootScope.showConnecticutDisclaimer = false;
                }
            });
        }

        function _onLogout() {
            $rootScope.$on("logout", function () {
                var cmsCache = ContentFac.retrieveCache();
                UserPromotionsSvc.stopPromotionsPoller();

                if (cmsCache.hasOwnProperty("LandingPages")) {
                    delete cmsCache.LandingPages;
                }

                ContentFac.restoreCache(cmsCache);

                if (applicationContext === "mobile") {
                    _stopVideos();
                }

                $rootScope.hasInitialDeposit = "";

                $rootScope.withdrawData = {
                    haveRequestedInfo: false,
                    pendingListWithdraw: [],
                };

                _.attempt(function () {
                    return window.sessionStorage.removeItem("equibaseId");
                });
            });
        }

        function _onSessionExpired() {
            $rootScope.$on("sessionExpired", function () {
                SecuritySvc.logout("?auto=true").then(function () {
                    UserPromotionsSvc.stopPromotionsPoller();
                    mediator.dispatch("TVG_LOGIN:DO_LOGOUT", {});
                    $timeout(function () {
                        $location.path("/").hash("sessionExpired");
                    });
                });
            });
        }

        function _onAppReady() {
            $rootScope.$on("appReady", function (event, component) {
                if ($location.path() === "/") {
                    var appReady = true;

                    if ($rootScope.appReady.hasOwnProperty(component)) {
                        $rootScope.appReady[component] = true;
                    }

                    for (var prop in $rootScope.appReady) {
                        if (!$rootScope.appReady[prop]) appReady = false;
                    }

                    if (appReady) {
                        if (!$rootScope.appReadyFired) {
                            $rootScope.appReadyFired = true;

                            for (var prop in $rootScope.appReady) {
                                $rootScope.appReady[prop] = false;
                            }
                        } else {
                            for (var prop in $rootScope.appReady) {
                                $rootScope.appReady[prop] = false;
                            }
                        }
                    }
                }
            });
        }

        function _onPageLoaded() {
            $rootScope.$on("pageLoaded", function (event, page) {
                if (!$rootScope.pageLoadedFired) {
                    $rootScope.pageLoadedFired = true;
                }
            });
        }

        /* ----- GTM Event Listeners ----- */

        function _gtmUserSession() {
            return $rootScope.$watch(
                "userSession",
                function (currentValue, prevValue) {
                    var isDirectUrlRegistration =
                        typeof prevValue === "undefined" &&
                        $location.path().indexOf("registration") === 1;
                    if (
                        (typeof prevValue === "undefined" &&
                            typeof currentValue !== "undefined") ||
                        isDirectUrlRegistration
                    ) {
                        _launchGTMEvent();
                        //to remove watch
                        if (!isDirectUrlRegistration) {
                            doneUserGTM = true;
                        }
                        _launchGTMAndGAAndUnwatch();
                    }
                }
            );
        }

        function _gtmSessionWatchers() {
            if ($rootScope.enableGTM) {
                // GTM login and logout watchers
                userSessionUnwatch = _gtmUserSession();
                GTMUserLoggedOutUnwatch = _gtmUserLoggedOut();
            }
        }

        function _gtmUserLoggedOut() {
            return $rootScope.$on("GTMUserLoggedOut", function () {
                doneUserGTM = true;
                _launchGTMEvent();
                _launchGTMAndGAAndUnwatch();
            });
        }

        function _launchGTMAndGAAndUnwatch() {
            // launch GTM and GA
            //_createGTMandGA();
            userSessionUnwatch();
        }

        /* ----- GTM Functions ----- */

        function _sendGTMMaintenanceError(aditionalParams) {
            var finalParams = {};
            var eventParams = {
                accountId: "",
                customerStatus: "",
                loginStatus: "Logged Out",
                registrationStatus: "Unregistered",
                gaEventCategory: "Site",
                residenceState: "",
                screenName: "Maintenance Page",
                sectionName: "Maintenance | Maintenance Page",
                siteVersion:
                    applicationContext === "desktop" ? "desktop" : "mobile_web",
            };

            if (typeof aditionalParams === "object") {
                finalParams = angular.extend(eventParams, aditionalParams);
            }
            GTMFac.GTMEvent().send($rootScope, "SiteError", finalParams);
        }

        function _sendPageLoadGTM(balance, customerStatus) {
            var eventParams = {
                balance: balance,
                customerStatus: customerStatus,
                event: "ga_pageload",
                graphVersion: "v2",
            };

            GTMFac.GTMEvent().send($rootScope, eventParams.event, eventParams);
        }

        function _launchGTMEvent() {
            if (_shouldLaunchPageViewEvent()) {
                // load track name and race number analitycs before pageload
                if (
                    window.dataLayer &&
                    $location.path() &&
                    $location.search().race
                ) {
                    window.dataLayer.push({
                        trackName: $location.path().split("/")[3] || "",
                        raceNumber: $location.search().race,
                    });
                }

                if ($rootScope.user !== null) {
                    UserProfileSvc.getAccountBalance($rootScope.user).then(
                        function (res) {
                            var balance = res.balance.toFixed(2).toString();
                            var customerStatus = $rootScope.hasNeverLogin
                                ? "New"
                                : "Returning";
                            _sendPageLoadGTM(balance, customerStatus);
                        }
                    );
                } else {
                    _sendPageLoadGTM(undefined, undefined);
                }
            }
        }

        function _shouldLaunchPageViewEvent() {
            return _isPathValid() && _hasSectionName();
        }

        function _isPathValid() {
            return $location.path().split("/").length > 1;
        }

        function _hasSectionName() {
            return GTMFac.Tvg4SectionName() !== "";
        }

        function _createGTMandGA() {
            var noScript = document.createElement("noscript");
            noScript.text =
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-' +
                ConfigurationFac.getGTMContainerId() +
                '" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
            document.getElementsByTagName("body").item(0).appendChild(noScript);

            /**
             * Google tag manager injection
             */
            (function (w, d, s, l, i, e, a) {
                w[l] = w[l] || [];
                w[l].push({
                    "gtm.start": new Date().getTime(),
                    event: "gtm.js",
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                if (e && a) {
                    j.src =
                        "https://www.googletagmanager.com/gtm.js?id=" +
                        i +
                        dl +
                        "&gtm_auth=" +
                        a +
                        "&gtm_preview=env-" +
                        e;
                } else {
                    j.src =
                        "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                }
                f.parentNode.insertBefore(j, f);
            })(
                window,
                document,
                "script",
                "dataLayer",
                "GTM-" + ConfigurationFac.getGTMContainerId(),
                ConfigurationFac.getGTMEnvironmentId(),
                ConfigurationFac.getGTMAuthenticationId()
            );

            (function (i, s, o, g, r, a, m) {
                i["GoogleAnalyticsObject"] = r;
                (i[r] =
                    i[r] ||
                    function () {
                        (i[r].q = i[r].q || []).push(arguments);
                    }),
                    (i[r].l = 1 * new Date());
                (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(
                window,
                document,
                "script",
                "//www.google-analytics.com/analytics.js",
                "ga"
            );

            ga("create", ConfigurationFac.getGAContainerId(), "auto");
        }

        /* ----- BounceExchange Functions ----- */
        function _addBounceExchange() {
            if ($rootScope.activeFeatures.bounceExchange) {
                (function (d) {
                    var e = d.createElement("script");
                    e.src =
                        d.location.protocol +
                        "//bounceexchange.com/tag/1171/i.js";
                    e.async = true;
                    d.getElementsByTagName("head")[0].appendChild(e);
                })(document);
            }
        }

        /* ----- Tooltip.IO Functions -----*/
        function _addTooltipIO() {
            var apiKyey = ConfigurationFac.getConfigParam(
                "tooltipIo",
                "apiKey"
            );
            if ($rootScope.activeFeatures.enableTooltip) {
                (function (d, s) {
                    var t = d.createElement(s),
                        e = d.getElementsByTagName(s)[0];
                    t.type = "text/javascript";
                    e.parentNode.insertBefore(t, e);
                    t.async = "async";
                    t.src = [
                        "https://cdn.tooltip.io/static/player.js?apiKey=",
                        apiKyey,
                    ].join("");
                })(document, "script");
            }
        }

        function _scrollToTop() {
            document.querySelector("html").scrollTop = 0;
            document.querySelector("body").scrollTop = 0;
        }

        function _stopVideos() {
            if ($rootScope.activeFeatures.videoProviderRCN) {
                if ($rootScope.playerMap) {
                    for (var instanceId in nlg_playerMap) {
                        if ($rootScope.playerMap.hasOwnProperty(instanceId)) {
                            $rootScope.playerMap[instanceId].stop();
                            $rootScope.videoIsPlaying = false;
                        }
                    }
                    $rootScope.playerMap = {};
                }
            } else if (typeof nlStop === "function") {
                nlStop("video");
            }
        }

        function _goToDeposit() {
            var destPath = "/my-funds";
            $timeout(function () {
                $location.path(destPath);
            });
        }

        function _onDepositOpen() {
            $rootScope.$on("open", function (event, data) {
                if (data === "Deposit") _goToDeposit();
            });
        }

        function _getQueryStringParam(param) {
            return _.filter($location.search(), function (v, k) {
                return param.toLowerCase() === k.toLowerCase();
            })[0];
        }

        function _setRFRCookie() {
            var rfr = _getQueryStringParam("rfr") || _getOrganicRFR();

            if (
                ConfigurationFac.getApplicationContextItem("product") !==
                    "ios" &&
                !_.isEmpty(_.trim(rfr))
            ) {
                $cookies.put("RFR", rfr, {
                    expires: moment().add(30, "days").toDate(),
                });
            }
        }

        function _getOrganicRFR() {
            var lastURL = $document[0].referrer;
            var cmsRFR;
            var rfrCookie = $cookies.get("RFR");
            var cmsConfig = _.attempt(function () {
                return angular.fromJson(
                    $filter("CMSValue")("registrationOrganicRFR")
                );
            });

            cmsConfig = !_.isError(cmsConfig) ? cmsConfig : {};
            // check if the referrer is present in the rfr values
            cmsRFR = _.filter(cmsConfig, function (value, key) {
                return lastURL.indexOf(key) > -1;
            });

            // set default tvg.com referrer if there is no refererrer for direct links
            if (!lastURL && !rfrCookie && cmsConfig && cmsConfig["tvg"]) {
                return cmsConfig["tvg"];
            }

            // don't override existing rfr when the referrer is from tvg.com
            if (
                rfrCookie &&
                cmsRFR &&
                cmsConfig &&
                _.values(cmsRFR)[0] === cmsConfig["tvg"]
            ) {
                return "";
            }

            // Override existing rfr if the referrer is from a search engine
            return rfrCookie && !_.values(cmsRFR)[0]
                ? ""
                : _.values(cmsRFR)[0] || "";
        }

        function _onAppLoaded() {
            var unbindAppLoaded = $rootScope.$on(
                "$viewContentLoaded",
                function (event) {
                    unbindAppLoaded();
                }
            );
        }

        function _onUpdateBalance() {
            $rootScope.$on("accountBalance_update", function () {
                mediator.dispatch("UPDATE_BALANCE_REQUEST", {});
            });
            $rootScope.$on("accountBalance_changed", function (event, args) {
                mediator.dispatch("ACCOUNT_BALANCE_CHANGED", {
                    balance: args,
                    noMediator: true,
                });
            });
        }

        function _addExternalLogin() {
            $rootScope.$on("externalLogin", function () {
                mediator.dispatch("TVG_LOGIN:VALIDATE_SESSION", {});
            });
        }

        return {
            initTVG4: _initTVG4,
            fnGo: _fnGo,
            fnPreviousButtonPressed: _fnPreviousButtonPressed,
            fnRaceButtonPressed: _fnRaceButtonPressed,
            fnNextButtonPressed: _fnNextButtonPressed,
            fnRefreshListPendingWithdraw: _fnRefreshListPendingWithdraw,
            fnGetLocationPath: _fnGetLocationPath,
            fnReloadApp: _fnReloadApp,
            onUser: _onUser,
            onLocationChangeStart: _onLocationChangeStart,
            onLocationChangeSuccess: _onLocationChangeSuccess,
            onRouteChangeStart: _onRouteChangeStart,
            onRouteChangeSuccess: _onRouteChangeSuccess,
            onLogin: _onLogin,
            onLogout: _onLogout,
            onSessionExpired: _onSessionExpired,
            onAppReady: _onAppReady,
            onPageLoaded: _onPageLoaded,
            onAppLoaded: _onAppLoaded,
            onUpdateBalance: _onUpdateBalance,
            sendGTMMaintenanceError: _sendGTMMaintenanceError,
            launchGTMEvent: _launchGTMEvent,
            createGTMandGA: _createGTMandGA,
            addBounceExchange: _addBounceExchange,
            onDepositOpen: _onDepositOpen,
            getNextRaceUrl: _getNextRaceUrl,
            getPreviousRaceUrl: _getPreviousRaceUrl,
            onScrollToTop: _onScrollToTop,
            addTooltipIO: _addTooltipIO,
            addExternalLogin: _addExternalLogin,
        };
    }

    return AppRootFac;
});

